import React from 'react';
import Link from 'next/link';
import { ArrowRightIcon } from '@heroicons/react/24/outline';

export default function IntroStatement() {
  return (
    <div className="flex flex-col w-full h-full px-3 py-5 md:p-5 lg:p-6 gap-y-5 bg-neutral-800 rounded-3xl overflow-hidden">
      <h2 className="text-white font-space-grotesk text-4xl lg:text-5xl uppercase">
        <span className="block w-full leading-9 md:leading-10">Desarrollo con</span>
        <span className="block w-full leading-9 md:leading-10 bg-clip-text text-transparent bg-gradient-to-r from-brand-yellow to-brand-orange">Visión Empresarial</span>
      </h2>
      <p className="text-sm md:text-base text-neutral-300">
        En el núcleo de cada negocio próspero está la tecnología que lo impulsa hacia adelante.
        En Colmena Digital, no solo damos vida a sus ideas; las elevamos a nuevas alturas
        con soluciones digitales versátiles y estratégicas, optimizadas para eficacia
        y retorno de inversión.
      </p>
      <Link href="/#soluciones" className="flex items-center px-4 py-2 text-white text-sm border border-white rounded-full w-fit hover:bg-white hover:text-neutral-900">
        Conozca nuestras Soluciones
        <ArrowRightIcon className="w-4 h-4 ml-1" />
      </Link>
    </div>
  );
}
