import Link from 'next/link';
import Image from 'next/image';
import React from 'react';
import { appDetails } from '../../data/appData';

const currentYear = new Date().getFullYear();

export default function Intro() {
  return (
    <section className="z-10 px-3 md:px-5 pt-16 md:pt-20" id="intro">
      <div className="relative mx-auto px-0 flex flex-col justify-center w-full max-w-xl md:max-w-5xl 2xl:max-w-7xl">
        <div className="w-full h-8 sm:h-10 md:h-16 relative">
          <Image src="/images/intro-text.svg" fill className="object-contain" alt="Soluciones estratégicas para su negocio" />
        </div>
        <h1 className="sr-only hidden">Soluciones Web para su Negocio</h1>
        <div className="flex justify-between items-center md:flex-nowrap mt-5 text-xs font-light gap-y-3 md:text-sm">
          <div className="w-full md:w-1/2 leading-5 flex">
            {`© ${currentYear} ${appDetails.companyName}`}
          </div>
          <div className="w-full md:w-1/2 leading-5 flex flex-col justify-end text-right gap-y-2 md:gap-y-1">
            <Link target="_blank" href={`tel:${appDetails.contact.phoneLink}`}>{appDetails.contact.phone}</Link>
            <Link target="_blank" href={`mailto:${appDetails.contact.email}`}>{appDetails.contact.email}</Link>
          </div>
        </div>
      </div>
    </section>
  );
}
