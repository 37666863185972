import React from 'react';
import Link from 'next/link';
import { ArrowUpRight } from 'feather-icons-react/build/IconComponents';
import ServiceCard from '../ui/SolutionCard';
import { featuredSolutions } from '../../data/appData';

export default function FeaturedSolutions() {
  return (
    <section className="relative z-10 px-3 md:px-5 pb-10 bg-white overflow-hidden">
      <div className="w-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-7xl">
        <div className="flex items-center justify-between p-5 mb-5 bg-neutral-200 md:mb-10 rounded-3xl">
          <h2 className="text-xl font-bold md:text-2xl font-space-grotesk uppercase">Soluciones Destacadas</h2>
          <Link
            href="/#contacto"
            className="flex items-center gap-2 px-4 py-2 text-xs text-white transition-all duration-150 bg-black border border-black rounded-full whitespace-nowrap md:px-6 md:py-3 md:text-sm w-fit"
          >
            Contáctanos
            <ArrowUpRight className="w-5 h-5" />
          </Link>
        </div>
        <div className="grid grid-cols-2 gap-3 md:gap-5 md:grid-cols-3">
          {featuredSolutions.map((item) => (
            <ServiceCard
              solution={item}
              key={item.name}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
