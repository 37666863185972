/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';

export default function SolutionCard({ solution }) {
  const lottieRef = useRef(null);

  useEffect(() => {
    const pauseTimer = setTimeout(() => {
      if (lottieRef.current) {
        lottieRef.current.pause();
      }
    }, 100);

    return () => clearTimeout(pauseTimer);
  }, []);

  const handleMouseEnter = () => {
    lottieRef.current.play();
  };

  const handleMouseLeave = () => {
    lottieRef.current.pause();
  };

  const style = {
    width: '100%',
    height: '100%',
    padding: 0,
    margin: 0,
    zIndex: 0,
    top: '-15%',
  };

  return (
    <div
      className="w-full h-[300px] sm:h-[320px] lg:h-[350px]
    rounded-3xl relative overflow-hidden
    transition
    !duration-500
    bg-neutral-200
    hover:bg-brand-yellow
    "
      data-aos="fade-up"
      data-aos-delay={solution.id * 200}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="absolute bottom-0 left-0 z-10 w-full h-full bg-gradient-to-t from-black/80 via-black/10 to-transparent rounded-3xl" />
      <Lottie
        lottieRef={lottieRef}
        animationData={solution.lottie}
        className="absolute top-0 left-0 p-0 !z-10 scale-[1.3] md:scale-[1.2]"
        style={style}
      />
      <div className="px-3 py-5 md:p-5 relative z-20 flex flex-col justify-end h-full text-white gap-y-3">
        <h3 className="text-xl md:text-2xl !leading-6 font-space-grotesk uppercase">{solution.name}</h3>
        <p className="text-xs text-white md:text-sm">
          {solution.description}
        </p>
      </div>
    </div>
  );
}

SolutionCard.propTypes = {
  solution: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.elementType.isRequired,
    lottie: PropTypes.object.isRequired,
  }).isRequired,
};
