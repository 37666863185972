import React from 'react';
import { solutions } from '../../data/appData';

export default function SolutionsIntro() {
  return (
    <section className="px-3 md:px-5 py-10 md:py-20 bg-white md:py-24" id="soluciones">
      <div className="mx-auto w-full max-w-xl md:max-w-5xl 2xl:max-w-7xl text-neutral-900">
        <p className="text-xl  md:text-3xl  mb-10">
          En Colmena Digital, enfrentamos cada proyecto combinando experticia técnica,
          altos estándares de ingeniería y una comprensión detallada de sus objetivos empresariales.
          Nos dedicamos a crear soluciones estratégicas que impulsan su negocio hacia adelante,
          asegurando resultados innovadores y beneficios reales.
        </p>
        <p className="text-lg mb-10">Descubra nuestras soluciones:</p>
        <div className="flex flex-wrap gap-3 mt-5 max-w-4xl">
          {solutions.map((item) => (
            <span
              key={item.id}
              className="w-fit flex items-center group
              gap-2 pl-1.5 pr-4 py-1
              text-sm text-neutral-900
              transition-all duration-150
              border border-neutral-900
              rounded-full
              hover:bg-neutral-900
              hover:text-white"
            >
              <span
                className="w-7 h-7 rounded-full bg-neutral-300 whitespace-nowrap
                flex items-center justify-center text-neutral-900
                 group-hover:bg-gradient-to-br
                 group-hover:from-brand-yellow
                 group-hover:to-brand-orange"
              >
                <item.icon className="w-4 h-4" />
              </span>
              {item.name}
            </span>
          ))}
        </div>
      </div>
    </section>
  );
}
