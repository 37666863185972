/* eslint-disable import/no-unresolved */
import React from 'react';
import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectCreative } from 'swiper/modules';
import { hero } from '../../data/appData';

export default function MainSlider() {
  return (
    <Swiper
      spaceBetween={30}
      centeredSlides
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      grabCursor
      effect="creative"
      creativeEffect={{
        prev: {
          shadow: true,
          translate: [0, 0, -400],
        },
        next: {
          translate: ['100%', 0, 0],
        },
      }}
      modules={[Autoplay, EffectCreative]}
      className="!rounded-3xl"
    >
      {hero.map((item) => (
        <SwiperSlide key={item.id} className="bg-black !rounded-3xl overflow-hidden relative">
          <div className="w-full h-full absolute z-0">
            <Image src={item.image} alt={`Proyecto ${item.name}`} fill className="object-cover" sizes="750px" priority={item.priority} placeholder="empty" />
            <div className="w-full h-full absolute z-2 bg-gradient-to-b from-neutral-900/0 to-neutral-900/90" />
          </div>
          <div className="flex items-end h-[400px] md:h-[450px] px-3 py-5 md:p-5 lg:p-6 relative z-5">
            <p className="flex-row w-full text-xs text-white md:text-base">
              <strong className="w-full flex mb-1 text-2xl md:text-3xl uppercase mr-2 leading-7 font-space-grotesk">{item.name}</strong>
              {item.description}
            </p>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
