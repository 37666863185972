import React from 'react';
import Image from 'next/image';

export default function Vision() {
  return (
    <section className="relative z-10 px-3 md:px-5 py-10 md:py-20 bg-white md:py-20" id="vision">
      <div className="w-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-7xl">
        <h2 className="mb-10 text-4xl font-space-grotesk md:text-5xl lg:text-6xl tracking-tighter">
          Diseño, Tecnología, y Estrategia
          {' '}
          <span className="relative italic font-normal font-playfair">
            Unificados
            <Image width={150} height={20} src="/images/underline.png" className="absolute right-0 -translate-y-[0px]" alt="Línea decorativa" />
          </span>
        </h2>
        <p className="my-5 text-base text-neutral-800 max-w-prose md:text-xl">
          En Colmena Digital, equilibramos la estética con la funcionalidad de manera efectiva.
          Nuestro enfoque se dirige hacia la esencia de cada proyecto, entregando soluciones
          web que no solo son eficientes sino también visualmente impactantes.
          Nos dedicamos a cumplir las expectativas con un trabajo de alta
          calidad que define el estándar en diseño y rendimiento.
        </p>

        <div className="flex flex-wrap gap-3 md:gap-5 mt-5 md:mt-10 md:flex-nowrap">
          <div className="w-full md:w-[60%] relative rounded-3xl overflow-hidden">
            <div className="w-full h-full absolute z-0 bg-neutral-300">
              <div className="w-full h-full absolute z-2 bg-gradient-to-b from-neutral-900/0 to-neutral-900/90" />
              <video
                aria-hidden="true"
                playsInline
                autoPlay
                muted
                loop
                disablePictureInPicture
                className="object-cover w-full h-full"
                alt="Video de fondo"
              >
                <source src="/videos/experiencia.webm" type="video/webm" />
                <source src="/videos/experiencia.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="flex flex-col justify-end w-full h-full px-3 py-5 md:px-5 md:p-8 text-white gap-y-3 md:gap-y-5 rounded-3xl z-10 relative">
              <h2 className="text-4xl font-space-grotesk lg:text-6xl uppercase">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-brand-yellow to-brand-orange">9 AÑOS</span>
                <br />
                Impulsando
                {' '}
                <br />
                Éxitos Digitales
              </h2>
            </div>
          </div>
          <div className="w-full md:w-[40%]">
            <div className="bg-neutral-900 !rounded-3xl px-3 py-5 md:px-5 md:p-8">
              <div className="h-[250px] md:h-[400px] flex flex-col mb-5">
                <h2 className="text-4xl md:text-6xl text-white tracking-tighter font-space-grotesk">
                  Impacto
                  <br />
                  <span className="font-playfair">Duradero</span>
                </h2>
                <p className="text-sm text-neutral-300 md:text-base mt-3 !leading-6">
                  Orgullosos de forjar soluciones que combinan funcionalidad excepcional
                  y diseño innovador. Con tecnología de punta, aseguramos que cada
                  proyecto destaque por años.
                </p>
              </div>
              <span
                className="w-full rounded-full flex items-center justify-center text-center px-4 py-3 text-xs md:text-sm md:py-2 text-white border border-white mb-3"
              >
                Proyectos vigentes por 5 años en promedio
              </span>
              <span
                className="w-full rounded-full flex items-center justify-center text-center px-4 py-3 text-xs md:text-sm md:py-2 text-neutral-900 bg-white border border-white"
              >
                Especialistas en Soluciones a Medida
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
