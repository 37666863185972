import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { appDetails } from '../../data/appData';

export default function HeadMeta({
  title = 'Colmena Digital',
  description = '',
  ogImage = null,
  keywords,
  publishedTime,
  schema,
}) {
  return (
    <Head>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <link sizes="57x57" href="apple/apple-touch-icon-57x57.png" />
      <link sizes="114x114" href="apple/apple-touch-icon-114x114.png" />
      <link sizes="72x72" href="apple/apple-touch-icon-72x72.png" />
      <link sizes="144x144" href="apple/apple-touch-icon-144x144.png" />
      <link sizes="60x60" href="apple/apple-touch-icon-60x60.png" />
      <link sizes="120x120" href="apple/apple-touch-icon-120x120.png" />
      <link sizes="76x76" href="apple/apple-touch-icon-76x76.png" />
      <link sizes="152x152" href="apple/apple-touch-icon-152x152.png" />
      <link rel="icon" type="image/png" href="favicon/favicon-196x196.png" sizes="196x196" />
      <link rel="icon" type="image/png" href="favicon/favicon-96x96.png" sizes="96x96" />
      <link rel="icon" type="image/png" href="favicon/favicon-32x32.png" sizes="32x32" />
      <link rel="icon" type="image/png" href="favicon/favicon-16x16.png" sizes="16x16" />
      <link rel="icon" type="image/png" href="favicon/favicon-128.png" sizes="128x128" />
      <meta name="application-name" content="&nbsp;" />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="msapplication-TileImage" content="mstile-144x144.png" />
      <meta name="msapplication-square70x70logo" content="mstile-70x70.png" />
      <meta name="msapplication-square150x150logo" content="mstile-150x150.png" />
      <meta name="msapplication-wide310x150logo" content="mstile-310x150.png" />
      <meta name="msapplication-square310x310logo" content="mstile-310x310.png" />
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      <meta property="og:locale" content="es_ES" />
      <meta property="og:site_name" content={appDetails.appName} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={`${title} | ${appDetails.appName}`} />
      {description && <meta property="og:description" content={description} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      {publishedTime && <meta property="article:published_time" content={publishedTime} />}
      {schema && <script type="application/ld+json">{JSON.stringify(schema)}</script>}
    </Head>
  );
}

HeadMeta.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  ogImage: PropTypes.string,
  keywords: PropTypes.string,
  publishedTime: PropTypes.string,
  schema: PropTypes.string,
};

HeadMeta.defaultProps = {
  ogImage: '',
  keywords: '',
};
