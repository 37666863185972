import React from 'react';
import Intro from '../components/sections/Intro';
import IntroStatement from '../components/sections/IntroStatement';
import MainSlider from '../components/sections/MainSlider';
import Marquee from '../components/ui/Marquee';
import SolutionsIntro from '../components/sections/SolutionsIntro';
import FeaturedSolutions from '../components/sections/FeaturedSolutions';
import Vision from '../components/sections/Vision';
import HeadMeta from '../components/layouts/HeadMeta';

export default function Home() {
  const data = {
    title: 'Colmena Digital: Soluciones Web Elite para Negocios Innovadores',
    description: 'Eleve su negocio con Colmena Digital, donde la visión se encuentra con la vanguardia tecnológica. Nuestro compromiso con soluciones web exclusivas y estratégicamente diseñadas garantiza que su marca no solo sobresalga, sino domine su industria. Atraiga el éxito con nuestra experiencia y creatividad sin precedentes.',
  };

  return (
    <>
      <HeadMeta {...data} />
      <section className="py-5 bg-neutral-200">
        <Intro />
        <section className="px-3 my-3 md:px-5 md:my-5">
          <div className="flex flex-wrap max-w-xl mx-auto md:flex-nowrap md:max-w-5xl 2xl:max-w-7xl">
            <div className="w-full md:w-[40%] pb-5 md:pb-0 md:pr-5">
              <IntroStatement />
            </div>
            <div className="w-full md:w-[60%]">
              <MainSlider />
            </div>
          </div>
        </section>
        <Marquee content="niches" />
      </section>
      <SolutionsIntro />
      <FeaturedSolutions />
      <Marquee content="caseOfUse" background="bg-gradient-to-r from-brand-yellow to-brand-orange" />
      <Vision />
      <Marquee content="commonGoals" background="bg-gradient-to-r from-brand-yellow to-brand-orange" />
    </>
  );
}
