import React from 'react';
import PropTypes from 'prop-types';
import M from 'react-fast-marquee';
import {
  niches, commonProblems, caseOfUse, commonGoals,
} from '../../data/appData';

export default function Marquee({ content, background = null }) {
  let listOfItems = [];
  let description = 'Estás son algunas de las industrias que ayudamos';

  switch (content) {
    case 'niches':
      listOfItems = niches;
      break;
    case 'caseOfUse':
      listOfItems = caseOfUse;
      description = 'Ejemplos de soluciones que podemos aplicar';
      break;
    case 'commonGoals':
      listOfItems = commonGoals;
      description = 'Algunos de los objetivos que podemos lograr';
      break;
    case 'commonProblems':
      listOfItems = commonProblems;
      description = 'Algunos de los problemas que mas frecuentemente resolvemos';
      break;
    default:
      listOfItems = niches;
      break;
  }

  return (
    <>
      <M autoFill className={`${background} py-2 text-base md:text-lg`}>
        {listOfItems.map((item) => (
          <div className="flex items-center gap-2 mx-2 pr-4" key={item.name}>
            <item.icon className="w-5 h-5" />
            {item.name}
          </div>
        ))}
      </M>
      <div className="w-full px-5 mt-3">
        <p className="text-center text-sm">{description}</p>
      </div>
    </>
  );
}

Marquee.propTypes = {
  content: PropTypes.string.isRequired,
  background: PropTypes.string,
};
